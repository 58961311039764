import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { AuthContext } from "../../context/auth-context";

const Navbar = () => {
  const authCtx = useContext(AuthContext);
  const { isLoggedIn, isAdmin, logout } = authCtx;

  const logoutHandler = () => {
    logout();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className="nav">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ color: "#fff", textDecoration: "none" }}>
              OCCZ
            </Link>
          </Typography>
          {isLoggedIn && (
            <div className="ctn">
              <Link
                to="/"
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  marginRight: "20px",
                }}
              >
                <Button variant="outlined" color="inherit">
                  Pretraga
                </Button>
              </Link>
              {isAdmin && (
                <Link
                  to="/add-new"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    marginRight: "20px",
                  }}
                >
                  <Button variant="outlined" color="inherit">
                    Dodaj člana
                  </Button>
                </Link>
              )}
              {isAdmin && (
                <Link
                  to="/create-new-user-account"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    marginRight: "20px",
                  }}
                >
                  <Button variant="outlined" color="inherit">
                    Create a new account
                  </Button>
                </Link>
              )}
              {isAdmin && (
                <Link
                  to="/logs"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    marginRight: "20px",
                  }}
                >
                  <Button variant="outlined" color="inherit">
                    Logs
                  </Button>
                </Link>
              )}
              <Link
                to="/login"
                style={{
                  color: "#fff",
                  textDecoration: "none",
                }}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={logoutHandler}
                >
                  Logout
                </Button>
              </Link>
            </div>
          )}
          {!isLoggedIn && (
            <Link to="/login" style={{ color: "#fff", textDecoration: "none" }}>
              <Button variant="outlined" color="inherit">
                Login
              </Button>
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
