import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Container from "@mui/material/Container";
import Navbar from "./components/Navbar/Navbar";
import Login from "./pages/Login";
import UsersPage from "./pages/UsersPage";
import AddNewUserPage from "./pages/AddNewUserPage";
import { AuthContext } from "./context/auth-context";
import AddNewEndUser from "./pages/AddNewEndUserPage";
import LogsPage from "./pages/LogsPage";

function App() {
  const authCtx = useContext(AuthContext);
  const { isLoggedIn, isAdmin } = authCtx;

  return (
    <div>
      <Navbar />

      <Container
        maxWidth={false}
        sx={{
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "3rem 0",
        }}
      >
        <Routes>
          {!isLoggedIn && <Route path="/login" element={<Login />} />}
          {isLoggedIn && <Route path="/" element={<UsersPage />} />}
          {isLoggedIn && isAdmin && (
            <Route path="/add-new" element={<AddNewUserPage />} />
          )}
          {isLoggedIn && isAdmin && (
            <Route
              path="/create-new-user-account"
              element={<AddNewEndUser />}
            />
          )}
          {isLoggedIn && isAdmin && (
            <Route path="/logs" element={<LogsPage />} />
          )}
          {!isLoggedIn && <Route path="/*" element={<Login />} />}
          {isLoggedIn && <Route path="/*" element={<UsersPage />} />}
        </Routes>
      </Container>
    </div>
  );
}

export default App;
