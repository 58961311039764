import React, { useContext, useEffect, useState } from "react";
import { Paper, Typography, Container, Alert } from "@mui/material";
import { Box } from "@mui/system";

import { blue } from "@mui/material/colors";
import { AuthContext } from "../context/auth-context";
import moment from "moment";

const url = process.env.REACT_APP_BACKEND_URL;

const LogsPage = () => {
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchLogs = async () => {
      const response = await fetch(`${url}/logs/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      setLogs(responseData);
    };
    fetchLogs();
  }, [token]);

  return (
    <Container maxWidth="sm">
      <Box
        mb={3}
        sx={{
          backgroundColor: blue[700],
          padding: "10px 0",
          color: "#fff",
        }}
      >
        <Typography variant="h4" component="h1" align="center">
          Logs
        </Typography>
      </Box>
      <Paper
        elevation={3}
        sx={{
          padding: "2rem 3rem",
          borderRadius: "12px",
          maxHeight: "70vh",
          height: "65vh",
          overflow: "auto",
        }}
      >
        {logs.length === 0 && <Typography>No logs found</Typography>}
        {logs.length > 0 &&
          logs.map((log) => (
            <Alert key={log._id} severity="info" sx={{ margin: "10px 0" }}>
              {log.message} -{" "}
              {moment(log.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </Alert>
          ))}
      </Paper>
    </Container>
  );
};

export default LogsPage;
