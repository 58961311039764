import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { AuthContext } from "../context/auth-context";
import { Checkbox, FormControlLabel } from "@mui/material";

const AddNewEndUser = () => {
  const authCtx = useContext(AuthContext);

  const { register, error, isLoading, isSuccess } = authCtx;

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const onFormSubmit = (event) => {
    event.preventDefault();
    register({
      email: email,
      password: password,
      name: name,
      isAdmin: isAdmin,
    });
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: "2rem 3rem", borderRadius: "12px" }}>
        <Typography variant="h4" component="h1" align="center">
          Create a new end user account
        </Typography>
        <Box
          component="form"
          onSubmit={onFormSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 3,
            marginTop: "2rem",
          }}
        >
          <TextField
            label="Name"
            variant="outlined"
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox onChange={(e) => setIsAdmin(e.target.checked)} />
            }
            label="IsAdmin"
          />
          <div style={{ marginTop: "10px" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
            >
              Create
            </Button>
          </div>
          <Typography variant="body1" align="center" color="red">
            {error}
          </Typography>
          <Typography variant="body1" align="center" color="green">
            {isSuccess}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default AddNewEndUser;
