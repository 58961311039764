import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Grid, MenuItem } from "@mui/material";
import { AuthContext } from "../context/auth-context";

//getting the select options from the constants file for ves and mz
import {
  VES_SELECT_OPTIONS,
  MZ_SELECT_OPTIONS,
  POSTROJBA_SELECT_OPTIONS,
  DUZNOST_SELECT_OPTIONS,
} from "../constants";

const url = process.env.REACT_APP_BACKEND_URL;

const AddNewUserPage = () => {
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(false);

  const [notes, setNotes] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [JMBG, setJMBG] = useState("");
  const [postrojba, setPostrojba] = useState("");
  const [ves, setVes] = useState("");
  const [MZ, setMZ] = useState("");
  const [duznost, setDuznost] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [focus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newUser = {
      name: name,
      surname: surname,
      date_of_birth: dateOfBirth,
      jmbg: JMBG,
      postrojba: postrojba,
      ves: ves,
      MZ: MZ,
      duznost: duznost,
      address: address,
      mobile: mobile,
      notes: notes,
    };
    const response = await fetch(`${url}/users/new`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newUser),
    });
    const responseData = await response.json();
    setMessage(responseData.message);
    setName("");
    setSurname("");
    setNotes("");
    setDateOfBirth("");
    setVes("");
    setMZ("");
    setJMBG("");
    setPostrojba("");
    setDuznost("");
    setAddress("");
    setMobile("");
    setFocused(false);
    setHasValue(false);
    setTimeout(() => {
      setMessage("");
      setIsLoading(false);
    }, 2000);
  };

  //function to allow only the numbers in the jmbg text input
  const onJMBGChange = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setJMBG(e.target.value);
    }
  };

  return (
    <Container>
      <Paper elevation={24} sx={{ padding: "2rem 3rem", borderRadius: "12px" }}>
        <Typography variant="h4" component="h1" align="center">
          Dodaj novog člana
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 3,
            marginTop: "2rem",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Ime"
                variant="outlined"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Prezime"
                variant="outlined"
                type="text"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                value={dateOfBirth}
                onFocus={onFocus}
                onBlur={onBlur}
                label="Datum rođenja"
                onChange={(e) => {
                  if (e.target.value) {
                    setDateOfBirth(e.target.value);
                    setHasValue(true);
                  } else {
                    setHasValue(false);
                  }
                }}
                type={hasValue || focus ? "date" : "text"}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="JMBG" //this should be 13-digit number, with check if it is entered exactly 13 numbers.
                variant="outlined"
                type="text"
                value={JMBG}
                inputProps={{
                  maxLength: 13,
                }}
                onChange={onJMBGChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="VES"
                variant="outlined"
                select
                value={ves} //value={Ves}
                onChange={(e) => setVes(e.target.value)} //setVes
              >
                {/* rendering the VES options */}
                {VES_SELECT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Dužnost"
                variant="outlined"
                select
                value={duznost} //duznost
                onChange={(e) => setDuznost(e.target.value)} //setDuznost
              >
                {/* rendering the VES options */}
                {DUZNOST_SELECT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Postrojba"
                variant="outlined"
                select
                value={postrojba}
                onChange={(e) => setPostrojba(e.target.value)}
              >
                {/* rendering the VES options */}
                {POSTROJBA_SELECT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="MZ"
                variant="outlined"
                select
                value={MZ} //value={MZ}
                onChange={(e) => setMZ(e.target.value)} //setMZ
              >
                {/* rendering the MZ options */}
                {MZ_SELECT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Adresa"
                variant="outlined"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Mobitel"
                variant="outlined"
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="NAPOMENA" //just some text field with some notes, if you can put it like textbox, at least 2 lines?
                variant="outlined"
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)} //setNotes
                multiline
                minRows={3}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "10px" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Dodaj
            </Button>
            <Typography variant="body1" mt={2}>
              {message}
            </Typography>
          </div>
        </Box>
      </Paper>
    </Container>
  );
};

export default AddNewUserPage;
