//we are exporting the values and labels for ves to be used in the select field of ves
export const VES_SELECT_OPTIONS = [
  { value: "VES 1", label: "VES 1" },
  { value: "VES 2", label: "VES 2" },
  { value: "VES 3", label: "VES 3" },
  { value: "VES 4", label: "VES 4" },
  { value: "VES 5", label: "VES 5" },
  { value: "VES 6", label: "VES 6" },
  { value: "VES 7", label: "VES 7" },
  { value: "VES 8", label: "VES 8" },
  { value: "VES 9", label: "VES 9" },
  { value: "VES 10", label: "VES 10" },
  { value: "VES 11", label: "VES 11" }, //from here up to VES24 are new values
  { value: "VES 12", label: "VES 12" },
  { value: "VES 13", label: "VES 13" },
  { value: "VES 14", label: "VES 14" },
  { value: "VES 15", label: "VES 15" },
  { value: "VES 16", label: "VES 16" },
  { value: "VES 17", label: "VES 17" },
  { value: "VES 18", label: "VES 18" },
  { value: "VES 19", label: "VES 19" },
  { value: "VES 20", label: "VES 20" },
  { value: "VES 21", label: "VES 21" },
  { value: "VES 22", label: "VES 22" },
  { value: "VES 23", label: "VES 23" },
  { value: "VES 24", label: "VES 24" }, //from 11 to here are new values
];

export const POSTROJBA_SELECT_OPTIONS = [
  { value: "Stozeri", label: "Stozeri" },
  { value: "Satnija-vod", label: "Satnija-vod" },
  { value: "Sluzbe", label: "Sluzbe" },
  { value: "Timovi", label: "Timovi" },
  { value: "Povjerenici", label: "Povjerenici" },
];

export const DUZNOST_SELECT_OPTIONS = [
  { value: "Zapovjednik stozera", label: "Zapovjednik stozera" },
  { value: "Zapovjednik", label: "Zapovjednik" },
  { value: "Zamjenik", label: "Zamjenik" },
  { value: "Pomocnik", label: "Pomocnik" },
  { value: "Pripadnik", label: "Pripadnik" },
];

//we are exporting the values and labels for age groups to be used in the select field of age groups
export const AGEGROUP_SELECT_OPTIONS = [
  { value: "Age Group from 18 to 28", label: "Groupa od 18 do 28" },
  { value: "Age Group from 29 to 45", label: "Groupa od 29 do 45" },
  { value: "Age Group from 46 to 60", label: "Groupa od 46 do 60" },
  { value: "Voj. 18-40", label: "Voj. 18-40" },
  { value: "Doc. 20-50", label: "Doc. 20-50" },
  { value: "Cas. 24-55", label: "Cas. 24-55" },
];

//we are exporting the values and labels for mz to be used in the select field of mz
export const MZ_SELECT_OPTIONS = [
  { value: "1-MZ Bivolje Brdo-173A028", label: "1-MZ Bivolje Brdo-173A028" },
  {
    value: "2-MZ Bobanovo Naselje-173A029",
    label: "2-MZ Bobanovo Naselje-173A029",
  },
  {
    value: "3-MZ Capljina I-173A033/173A001/173A002/173A003/173A004/",
    label: "3-MZ Capljina I-173A033/173A001/173A002/173A003/173A004/",
  },
  {
    value: "4-MZ Capljina II-173A024/173A026/173A032/173A033",
    label: "4-MZ Capljina II-173A024/173A026/173A032/173A033",
  },
  { value: "5-MZ Celjevo-173A005", label: "5-MZ Celjevo-173A005" },
  { value: "6-MZ Domanovici-173A007", label: "6-MZ Domanovici-173A007" },
  {
    value: "7-MZ Dracevo-Sjekose-173A008",
    label: "7-MZ Dracevo-Sjekose-173A008",
  },
  { value: "8-MZ Dretelj-173A009", label: "8-MZ Dretelj-173A009" },
  { value: "9-MZ Gabela-173A010", label: "9-MZ Gabela-173A010" },
  { value: "10-MZ Gabela Polje-173A011", label: "10-MZ Gabela Polje-173A011" },
  { value: "11-MZ Gnjilista-173A012", label: "11-MZ Gnjilista-173A012" },
  {
    value: "12-MZ Gorica-Struge-173A017",
    label: "12-MZ Gorica-Struge-173A017",
  },
  { value: "13-MZ Grabovina-173A013", label: "13-MZ Grabovina-173A013" },
  {
    value: "14-MZ Klepci-Prebilovci-173A025",
    label: "14-MZ Klepci-Prebilovci-173A025",
  },
  {
    value: "15-MZ Lokve-Stanojevići-173A030",
    label: "15-MZ Lokve-Stanojevići-173A030",
  },
  { value: "16-MZ Oplicici-173A014", label: "16-MZ Oplicici-173A014" },
  {
    value: "17-MZ Pocitelj-Hotanj-Ševaš njive 173A015/173A031",
    label: "17-MZ Pocitelj-Hotanj-Ševaš njive 173A015/173A031",
  },
  { value: "18-MZ Prcavci-173A016", label: "18-MZ Prcavci-173A016" },
  { value: "19-MZ Prebilovci-173A025", label: "19-MZ Prebilovci-173A025" },
  {
    value: "20-MZ Svitava-Bajovci-173A018",
    label: "20-MZ Svitava-Bajovci-173A018",
  },
  { value: "21-MZ Surmanci-173A019", label: "21-MZ Surmanci-173A019" },
  {
    value: "22-MZ Suskovo Naselje (Nakovanj-Vlaka-Ivanuša)-173A027",
    label: "22-MZ Suskovo Naselje (Nakovanj-Vlaka-Ivanuša)-173A027",
  },
  { value: "23-MZ Tasovcici-173A020", label: "23-MZ Tasovcici-173A020" },
  { value: "24-MZ Trebizat-173A021", label: "24-MZ Trebizat-173A021" },
  { value: "25-MZ Visici-173A022", label: "25-MZ Visici-173A022" },
  { value: "26-MZ Zvirovici-173A023", label: "26-MZ Zvirovici-173A023" },
  { value: "27-MZ Doljani-173A006", label: "27-MZ Doljani-173A006" },
];
