import React, { useEffect, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { CircularProgress, Grid, Pagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Container, Typography, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { blue } from "@mui/material/colors";
import { AuthContext } from "../context/auth-context";
import moment from "moment";

//getting the select options from the constants file for ves, mz and age group
import {
  VES_SELECT_OPTIONS,
  MZ_SELECT_OPTIONS,
  AGEGROUP_SELECT_OPTIONS,
  POSTROJBA_SELECT_OPTIONS,
  DUZNOST_SELECT_OPTIONS,
} from "../constants";

const url = process.env.REACT_APP_BACKEND_URL;

const UsersPage = () => {
  const authCtx = useContext(AuthContext);
  const { token } = authCtx;

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [dateOfBirthFrom, setDateOfBirthFrom] = useState("");
  const [dateOfBirthTo, setDateOfBirthTo] = useState("");
  const [jmbg, setJmbg] = useState("");
  const [postrojba, setPostrojba] = useState("");
  const [ves, setVES] = useState(""); //this changed from workPlace to VES
  const [MZ, setMZ] = useState(""); //state for MZ
  const [ageGroup, setAgeGroup] = useState(""); //state for age group
  const [duznost, setDuznost] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [focusDateFrom, setFocusedDateFrom] = useState(false);
  const [focusDateTo, setFocusedDateTo] = useState(false);
  const [hasValueDateFrom, setHasValueDateFrom] = useState(false);
  const [hasValueDateTo, setHasValueDateTo] = useState(false);
  const onFocusDateFrom = () => setFocusedDateFrom(true);
  const onBlurDateFrom = () => setFocusedDateFrom(false);
  const onFocusDateTo = () => setFocusedDateTo(true);
  const onBlurDateTo = () => setFocusedDateTo(false);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isDataFiltered, setIsDataFiltered] = useState(false);

  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const fetchFilteredData = async () => {
    setIsLoading(true);
    const body = {
      name,
      surname,
      dateOfBirthFrom,
      dateOfBirthTo,
      jmbg,
      postrojba,
      ves,
      mz: MZ,
      ageGroup,
      duznost,
      address,
      mobile,
    };
    console.log(body);
    const response = await fetch(
      `${url}/users/search?pageSize=100&page=${page}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );
    const responseData = await response.json();
    setFilteredData(responseData.users);
    setPagesCount(responseData.pages);
    setTotalCount(responseData.total);
    setIsLoading(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const response = await fetch(`${url}/users/all?pageSize=100&page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response.json();
    setData(responseData.users);
    setFilteredData(responseData.users);
    setPagesCount(responseData.pages);
    setTotalCount(responseData.total);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isDataFiltered) {
      fetchFilteredData();
    } else {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, isDataFiltered]);

  const handleSearch = async (e) => {
    setIsDataFiltered(true);
    e.preventDefault();
    fetchFilteredData();
  };

  //function to allow only the numbers in the jmbg text input
  const onJMBGChange = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      setJmbg(e.target.value);
    }
  };

  return (
    <Container maxWidth={false}>
      <Box
        mb={3}
        sx={{
          backgroundColor: blue[700],
          padding: "10px 0",
          color: "#fff",
        }}
      >
        <Typography variant="h4" component="h1" align="center">
          Pretraga
        </Typography>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden", padding: 3 }}>
        <Box
          component="form"
          onSubmit={handleSearch}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 3,
            marginTop: "2rem",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Ime"
                variant="outlined"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Prezime"
                variant="outlined"
                type="text"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Postrojba"
                variant="outlined"
                select
                value={postrojba}
                onChange={(e) => setPostrojba(e.target.value)}
              >
                {/* rendering the VES options */}
                {POSTROJBA_SELECT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="JMBG"
                variant="outlined"
                type="text"
                value={jmbg}
                inputProps={{
                  maxLength: 13,
                }}
                onChange={onJMBGChange} //setWorkPosition was original
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="VES"
                variant="outlined"
                select
                value={ves} //value={Ves}
                onChange={(e) => setVES(e.target.value)} //setVes
              >
                {/* rendering the VES options */}
                {VES_SELECT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Dužnost"
                variant="outlined"
                select
                value={duznost} //duznost
                onChange={(e) => setDuznost(e.target.value)} //setDuznost
              >
                {/* rendering the VES options */}
                {DUZNOST_SELECT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="MZ"
                variant="outlined"
                select
                value={MZ} //value={MZ}
                onChange={(e) => setMZ(e.target.value)} //setMZ
              >
                {/* rendering the MZ options */}
                {MZ_SELECT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Dobna skupina"
                variant="outlined"
                select
                value={ageGroup} //value={AgeGroup}
                onChange={(e) => setAgeGroup(e.target.value)} //setAgeGroup
              >
                {/* rendering the MZ options */}
                {AGEGROUP_SELECT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Adresa"
                variant="outlined"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Mobitel"
                variant="outlined"
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                value={dateOfBirthFrom}
                onFocus={onFocusDateFrom}
                onBlur={onBlurDateFrom}
                label="Datum rođenja (od)"
                onChange={(e) => {
                  if (e.target.value) {
                    setDateOfBirthFrom(e.target.value);
                    setHasValueDateFrom(true);
                  } else {
                    setHasValueDateFrom(false);
                  }
                }}
                type={hasValueDateFrom || focusDateFrom ? "date" : "text"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                value={dateOfBirthTo}
                onFocus={onFocusDateTo}
                onBlur={onBlurDateTo}
                label="Datum rođenja (do)"
                onChange={(e) => {
                  if (e.target.value) {
                    setDateOfBirthTo(e.target.value);
                    setHasValueDateTo(true);
                  } else {
                    setHasValueDateTo(false);
                  }
                }}
                type={hasValueDateTo || focusDateTo ? "date" : "text"}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "10px" }}>
            <Button type="submit" variant="contained" color="primary">
              Pretraži
            </Button>
            {isDataFiltered && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  setName("");
                  setSurname("");
                  setDateOfBirthFrom("");
                  setDateOfBirthTo("");
                  setVES("");
                  setJmbg("");
                  setPostrojba(""); // I added this
                  setMZ("");
                  setAgeGroup("");
                  setDuznost("");
                  setAddress("");
                  setMobile("");
                  setFocusedDateFrom(false);
                  setFocusedDateTo(false);
                  setHasValueDateFrom(false);
                  setHasValueDateTo(false);
                  setIsDataFiltered(false);
                  setFilteredData(data);
                }}
                sx={{ margin: "0 20px" }}
              >
                Resetiraj pretragu
              </Button>
            )}
          </div>
        </Box>
      </Paper>
      <Box
        mt={6}
        mb={3}
        sx={{
          backgroundColor: blue[700],
          color: "#fff",
          padding: "10px 0",
        }}
      >
        <Typography variant="h4" component="h1" align="center">
          Rezultati
        </Typography>
      </Box>
      <Typography variant="body1" component="p" my={3}>
        Broj pronađenih rezultata: {totalCount}
      </Typography>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>R.br.</TableCell>
                <TableCell>Ime</TableCell>
                <TableCell>Prezime</TableCell>
                <TableCell>Datum rođenja</TableCell>
                <TableCell>JMBG</TableCell>
                <TableCell>Postrojba</TableCell>
                <TableCell>VES</TableCell>
                <TableCell>MZ</TableCell>
                <TableCell>Duznost</TableCell>
                <TableCell>Adresa</TableCell>
                <TableCell>Mobitel</TableCell>
                <TableCell>Napomena</TableCell>
              </TableRow>
            </TableHead>
            {!isLoading && (
              <TableBody>
                {filteredData.map((user, index) => (
                  <TableRow
                    key={user._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* the problem is here it should be start from 1 to 100 for page 1, for page 2 it should start from 101 to 200 and so on */}
                    <TableCell>{index + 1 + (page - 1) * 100}</TableCell>
                    <TableCell component="th" scope="row">
                      {user.name}
                    </TableCell>
                    <TableCell>{user.surname}</TableCell>
                    <TableCell>
                      {moment(user.date_of_birth).format("L")}
                    </TableCell>
                    <TableCell>{user.jmbg}</TableCell>
                    <TableCell>{user.postrojba}</TableCell>
                    <TableCell>{user.ves}</TableCell>
                    <TableCell>{user.MZ}</TableCell>
                    <TableCell>{user.duznost}</TableCell>
                    <TableCell>{user.address}</TableCell>
                    <TableCell>{user.mobile}</TableCell>
                    <TableCell component="th" scope="row">
                      {user.notes}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      padding: "20px 0",
                    },
                  }}
                >
                  <TableCell colSpan={12} align="center">
                    <CircularProgress color="primary" size={50} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <Box
        mt={6}
        mb={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          count={pagesCount}
          variant="outlined"
          shape="rounded"
          onChange={(e, page) => {
            setPage(page);
          }}
          size="large"
        />
      </Box>
    </Container>
  );
};

export default UsersPage;
