import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { AuthContext } from "../context/auth-context";

const Login = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const { login, error, isLoading, isLoggedIn } = authCtx;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onFormSubmit = (event) => {
    event.preventDefault();
    login({ email: email, password: password });
  };

  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      navigate("/");
    }
  }, [isLoggedIn, isLoading, navigate]);

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: "2rem 3rem", borderRadius: "12px" }}>
        <Typography variant="h4" component="h1" align="center">
          Login
        </Typography>
        <Box
          component="form"
          onSubmit={onFormSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 3,
            marginTop: "2rem",
          }}
        >
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ marginTop: "10px" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
            >
              Login
            </Button>
          </div>
          <Typography variant="body1" align="center" color="red">
            {error}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;
